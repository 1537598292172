import {graphql} from 'gatsby';
import {css, cx} from 'linaria';
import * as React from 'react';
import Page, {PageProps} from '../components/Page';
import Product, {ProductData} from '../components/Product';
import Sidebar, {SidebarData} from '../components/Sidebar';

export default ({
  data: {
    products: {nodes: products},
    ...sidebarData
  },
  location,
  ...props
}: {data: HomeData} & PageProps) => (
  <Page location={location} {...props}>
    <div
      className={cx(
        'centered',
        css`
          padding-top: 0;
        `,
      )}>
      {products
        .sort((a, b) => b.data.rank - a.data.rank)
        .map(({id, data}) => (
          <Product key={id} product={data} />
        ))}
      <Sidebar data={sidebarData} pathname={location.pathname} />
    </div>
  </Page>
);

type HomeData = SidebarData & {
  products: {
    nodes: {
      id: string;
      data: ProductData;
    }[];
  };
};

export const query = graphql`
  {
    ...SidebarFragment
    products: allAirtable(
      filter: {
        table: {eq: "Products"}
        data: {
          brand: {ne: null}
          name: {ne: null}
          type: {ne: null}
          description: {ne: null}
          price: {ne: null}
          link: {ne: null}
          store: {elemMatch: {id: {ne: null}}}
          category: {elemMatch: {id: {ne: null}}}
          image: {id: {ne: null}}
        }
      }
    ) {
      nodes {
        id
        data {
          ...ProductFragment
        }
      }
    }
  }
`;
